<template>
    <component :is="as" @click="doSave" :class="className" :disabled="isDisabled" :style="{ 'cursor': !isDisabled ? 'pointer' : 'inherit' }">
        <div class="spinner-border spinner-border-sm me-1" role="status" v-if="isSaving && as === 'button'">
            <span class="sr-only"></span>
        </div>
        <slot :isSaving="isSaving" :isDisabled="isDisabled">
            <template v-if="as !== 'i' && as !== 'img'">
                {{ $t('Save') }}
            </template>
        </slot>
    </component>
</template>

<script setup>
/**
* Renders save button with spinner while running.
* Need to provide dataObject or row, if data object provided will save current row.
 Add default slot with text or/and icon to override default.
*@definition
*/
import { useAttrs, computed } from 'vue';
import { getDataObjectById } from 'o365.vue.ts'

const emits = defineEmits(['onSave', "saved"]);

const props = defineProps({
    dataObject: {
        type: Object,
        default: undefined
    },
    row: {
        type: Object,
        default: undefined
    },
    as: {
        type: String,
        default: "button"
    },
    disabledClasses: {
        type: String,
        required: false,
        default: "",
    },
    onAfter: Function,
});

const _dataObject = computed(() => {
    if (props.dataObject) {
        return props.dataObject
    } else {
        const dataObjectId = props.row.dataObjectId;
        if (dataObjectId === undefined) { return; }
        const appId = props.row.appId;
        return getDataObjectById(dataObjectId, appId);
    }
});

const _row = computed(() => {
    return props.row ?? _dataObject.value.current;
});

const isSaving = computed(() => {
    return _row.value.isSaving ?? false;
});

const isDisabled = computed(() => {
    return !_dataObject.value || isSaving.value || !_row.value.hasChanges;
});

const attrs = useAttrs();

const className = computed(() => {
    return (attrs.class ? '' : 'btn btn-sm btn-link') + " " + (isDisabled.value ? props.disabledClasses : "");
});

async function doSave() {
    if (_row.value?.disableSaving) {
        _row.value.disableSaving = false;
    }
    emits('onSave', _row.value);
    await _dataObject.value.save(_row.value.index);
    emits("saved", _row.value);

    // emit does not work if the component unmounts the moment a save has completed
    if (typeof props.onAfter === "function") {
        props.onAfter(_row.value);
    }
}
</script>
